import _setPrototypeOf from "./setPrototypeOf.js";
var exports = {};
var setPrototypeOf = _setPrototypeOf;
function _inherits(t, e) {
  if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
  t.prototype = Object.create(e && e.prototype, {
    constructor: {
      value: t,
      writable: !0,
      configurable: !0
    }
  }), Object.defineProperty(t, "prototype", {
    writable: !1
  }), e && setPrototypeOf(t, e);
}
exports = _inherits, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;